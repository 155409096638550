<template>
	<div class="company">
		<w-navTab titleText="爱心企业"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="head-picture"></div>
		<div class="head-title">
			<scroll-tab :menu="navList" :active="active" @click="onTab"></scroll-tab>
		</div>
		<div class="middle">
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="middle-item" v-for="item in companyList">
					<div class="middle-item-left"><img :src="item.image" alt="" /></div>
					<div class="middle-item-right">
						<div class="item-right-title">{{ item.name }}</div>
						<div class="item-right-box">
							<div class="item-right-box-picture">
								<img src="@/assets/img/icon203.png" alt="" />
							</div>
							<div class="item-right-box-text">积分：{{ item.integral }}</div>
						</div>
						<div class="item-right-box">
							<div class="item-right-box-picture">
								<img src="@/assets/img/icon202.png" alt="" />
							</div>
							<div class="item-right-box-text">{{ item.address }}</div>
						</div>
					</div>
				</div>
			</VantList>
		</div>
	</div>
</template>

<script>
	import VantList from '@/components/VantList';
	import LoadingPage from '@/components/LoadingPage';
	import ScrollTab from '@/components/ScrollTab';
	import CompanyApi from '@/api/company';

	export default {
		name: 'Company',
		data() {
			return {
				active: 0,
				navList: [{
					id: 0,
					title: '全部'
				}],
				loading: true,
				finished: false,
				isEmpty: false,
				companyList: [],
				loadingPage: true,
				paramsPage: 1,
				paramsLimit: 10,
				paramsGroupId: 0
			};
		},
		created() {
			this.getCompanyGroup();
			this.onLoad();
		},
		methods: {
			onTab(index) {
				this.active = index;
				this.companyList = [];
				this.paramsGroupId = this.navList[index].id;
				this.paramsPage = 1;
				this.finished = false;
				this.isEmpty = false;
				this.onLoad();
			},
			getCompanyGroup() {
				CompanyApi.getGroup()
					.then(result => {
						this.navList = this.navList.concat(result.data);
					})
					.catch(error => {
						console.log(error);
					});
			},
			onLoad() {
				this.loading = true;

				let params = {
					page: this.paramsPage,
					limit: this.paramsLimit,
					group_id: this.paramsGroupId
				};

				CompanyApi.getList(params)
					.then(result => {
						this.paramsPage++;
						this.companyList = this.companyList.concat(result.data.list);

						this.loading = false;
						this.loadingPage = false;
						
						if (this.companyList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.companyList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			}
		},
		components: {
			VantList,
			ScrollTab,
			LoadingPage
		}
	};
</script>

<style scoped lang="less">
	.company {
		min-height: 100vh;

		.head-picture {
			width: 100%;
			height: 200px;
			background: url('~@/assets/img/test251.png');
			background-size: 100% 100%;
		}

		.head-title {
			padding: 15px 10px 0 10px;
			background: #f2f2f2;
			border-radius: 15px 15px 0px 0px;
			margin-top: -15px;
		}

		.middle {
			padding: 0 10px 10px 10px;
			box-sizing: border-box;

			.middle-item {
				background-color: #fff;
				border-radius: 6px;
				overflow: hidden;
				margin-top: 10px;
				padding: 10px;
				box-sizing: border-box;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.middle-item-left {
					width: 132px;
					min-width: 132px;
					height: 90px;

					img {
						width: 100%;
						height: 100%;
						border-radius: 6px;
						object-fit: cover;
					}
				}

				.middle-item-right {
					padding-left: 11px;

					.item-right-title {
						width: 190px;
						min-width: 190px;
						font-size: 18px;
						line-height: 20px;
						color: #333;
					}

					.item-right-box {
						margin-top: 5px;
						display: flex;
						justify-content: flex-start;

						.item-right-box-picture {
							width: 16px;
							min-width: 16px;
							height: 16px;
							margin-top: 2px;
							margin-right: 4px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.item-right-box-text {
							font-size: 14px;
							line-height: 20px;
							color: #666;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
</style>
